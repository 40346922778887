import { AssetType, ApiAssetResponse, apiTemplateResponse, MyAvatarsResponse, Template, assignedTemplateResponse, ApiResponse, EditType, JsonResponseRPM } from "./RPMTypes";

type FetchOptions = {
  method: string;
  body?: object;
  headers?: HeadersInit;
};

export async function apiRequest<T>(
  endpoint: string,
  options: FetchOptions
): Promise<{ data: T | null; error?: string }> {
  try {
    const response = await fetch(endpoint, {
      method: options.method,
      headers: {
        'Content-Type': 'application/json',
        ...(options.headers || {})
      },
      body: options.body ? JSON.stringify(options.body) : undefined,
    });

    if (!response.ok) {
      throw new Error(`Error! status: ${response.status}`);
    }

    const data: T = await response.json();
    console.log(data);

    return { data };
  } catch (error) {
    let errorMessage = 'An unknown error occurred';
    if (error instanceof Error) {
      errorMessage = error.message;
    }
    return { data: null, error: errorMessage };
  }
}


// Load Templates from RPM to apply to guest accounts prior to editing/assigning assets
export async function getTemplates(rpmBearerToken: string): Promise<apiTemplateResponse | null> {
  try {
    const response = await apiRequest<apiTemplateResponse>(
      'https://api.readyplayer.me/v2/avatars/templates',
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${rpmBearerToken}`,
        },
      }
    );

    if (response.error) {
      console.error('API Error:', response.error || 'Unknown error occurred');
      return null;
    }

    return response.data as apiTemplateResponse;
  } catch (error) {
    console.error('Unexpected error while fetching templates:', error);
    return null;
  }
}

// For users with RPM accounts already to fetch avatars created that are partnered with This Application
export async function getMyAvatarsFromRPM(rpmBearerToken: string, rpmIdRef: string | null): Promise<MyAvatarsResponse | null> {
  try {
    const response = await apiRequest<MyAvatarsResponse>(`https://api.readyplayer.me/v1/avatars?partner=${"testapp-uy5uxo"}&id=${rpmIdRef}`, {
      method: 'GET',
      headers: {
        "Authorization": `Bearer ${rpmBearerToken}`
      },
    });

    if (response.error) {
      console.error('API Error:', response.error || 'Unknown error occurred');
      return null;
    }

    return response.data as MyAvatarsResponse;
  } catch (error) {
    console.error('Unexpected error while fetching templates:', error);
    return null;
  }
}

//For Equiping all assets and setting colours (except top and bottom, these are handled below this is due to "outfit" causing top and bottom not to be applied when changed)
export async function equipAssets(asset: AssetType, rpmBearerToken: string, avatarId: string) {
  let type = ""
  switch (asset.type) {
    case "beard":
      type = "beardStyle"
      break;
    case "eye":
      type = "eyeColor"
      break;
    case "eyebrows":
      type = "eyebrowStyle"
      break;
    case "eyeshape":
      type = "eyeShape"
      break;
    case "facemask":
      type = "faceMask"
      break;
    case "faceshape":
      type = "faceShape"
      break;
    case "glasses":
      type = "glasses"
      break;
    case "hair":
      type = "hairStyle"
      break;
    case "lipshape":
      type = "lipShape"
      break;
    case "noseshape":
      type = "noseShape"
      break;
    default:
      type = asset.type
      break;
  }

  const response = await apiRequest<ApiAssetResponse>(`https://api.readyplayer.me/v2/avatars/${avatarId}`, {
    method: 'PATCH',
    headers: {
      "X-APP-ID": "6620f37fc062d6de28094e5d",
      "Authorization": `Bearer ${rpmBearerToken}`
    },
    body: {
      data: {
        assets: {
          [type]: asset.id
        }
      }
    },
  });

  if (response.error) {
    console.error('API Error:', response.error);
  } else {
    console.log('API Assets:', response.data?.data);

  }
}

export async function applyEditsToLoggedinUserTemplate(data: EditType, rpmBearerToken: string, avatarId: string) {

  const response = await apiRequest<ApiAssetResponse>(`https://api.readyplayer.me/v2/avatars/${avatarId}`, {
    method: 'PATCH',
    headers: {
      "X-APP-ID": "6620f37fc062d6de28094e5d",
      "Authorization": `Bearer ${rpmBearerToken}`
    },
    body: data,
  });

  if (response.error) {
    console.error('API Error:', response.error);
  } else {
    console.log('API Assets:', response.data?.data);

  }
}

// switch the gender of selected avatar
export async function changeGender(asset: AssetType, rpmBearerToken: string, avatarId: string) {

  const response = await apiRequest<ApiAssetResponse>(`https://api.readyplayer.me/v2/avatars/${avatarId}`, {
    method: 'PATCH',
    headers: {
      "X-APP-ID": "6620f37fc062d6de28094e5d",
      "Authorization": `Bearer ${rpmBearerToken}`
    },
    body: {
      data: {
        [asset.type]: asset.id
      }
    },
  });

  if (response.error) {
    console.error('API Error:', response.error);
  } else {
    console.log('API Assets:', response.data?.data);

  }
}

// change body shape but not working at present not sure why
export async function changeBodyShape(asset: AssetType, rpmBearerToken: string, avatarId: string) {

  const response = await apiRequest<ApiAssetResponse>(`https://api.readyplayer.me/v2/avatars/${avatarId}`, {
    method: 'PATCH',
    headers: {
      "X-APP-ID": "6620f37fc062d6de28094e5d",
      "Authorization": `Bearer ${rpmBearerToken}`
    },
    body: {
      data: {
        bodyShape: asset.id
      }
    }
  }
  )

  if (response.error) {
    console.error('API Error:', response.error);
  } else {
    console.log('API Assets:', response.data?.data);

  }
}

// assign a base avatar to the account created chosen from templates
export async function assignTemplateToAvatar(template: Template, rpmBearerToken: string): Promise<assignedTemplateResponse | null> {
  try {
    const response = await apiRequest<assignedTemplateResponse>(
      `https://api.readyplayer.me/v2/avatars/templates/${template.id}`,
      {
        method: 'POST',
        body: {
          data: {
            partner: "testapp-uy5uxo",
            bodyType: "fullbody",
          }
        },
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${rpmBearerToken}`,
          "x-api-key": "sk_live_ukCGG9Uw2rD1mCJAlV0Yk1JDyozjAIgvTohC",
        },
      }
    );

    if (response.error) {
      console.error('API Error:', response.error || 'Unknown error occurred');
      return null;
    }

    return response.data as assignedTemplateResponse;
  } catch (error) {
    console.error('Unexpected error while fetching templates:', error);
    return null;
  }
}

//Save the avatar with equiped assets/or just save template
export async function save(id: string): Promise<EditType | null> {
  try {
    const response = await apiRequest<EditType>(
      'https://api.readyplayer.me/v2/avatars/' + id,
      {
        method: 'PUT',
        body: { data: { applicationId: '6620f37fc062d6de28094e5d' } },
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': 'sk_live_ukCGG9Uw2rD1mCJAlV0Yk1JDyozjAIgvTohC',
        },
      }
    );

    if (response.error) {
      console.error('API Error:', response.error || 'Unknown error occurred');
      return null;
    }

    return response.data as EditType;
  } catch (error) {
    console.error('Unexpected error while fetching templates:', error);
    return null;
  }
};

// return the assets from RPM based on type
export async function getAssetsByType(id: string, type: string, rpmBearerToken: string, rpmId: string): Promise<ApiAssetResponse | null> {

  try {
    const response = await apiRequest<ApiAssetResponse>(`https://api.readyplayer.me/v1/assets?filter=usable-by-user-and-app&filterApplicationId=${"6620f37fc062d6de28094e5d"}&filterUserId=${rpmId}&page=1&limit=100&type=${type}`, {
      method: 'GET',
      headers: {
        "X-APP-ID": "6620f37fc062d6de28094e5d",
        "Authorization": `Bearer ${rpmBearerToken}`
      },
    });

    if (response.error) {
      console.error('API Error:', response.error || 'Unknown error occurred');
      return null;
    }

    return response.data as ApiAssetResponse;
  } catch (error) {
    console.error('Unexpected error while fetching templates:', error);
    return null;
  }
}

// hhandle the top or bottom with this endpoint as using the other doesnt apply top or bottomw when an outfit has been applied
export async function equipTopOrBottomIfOutfitApplied(asset: AssetType, rpmBearerToken: string, avatarIdRef: string) {
  const response = await apiRequest<ApiAssetResponse>(`https://api.readyplayer.me/v1/avatars/${avatarIdRef}/equip`, {
    method: 'PUT',
    headers: {
      "X-APP-ID": "6620f37fc062d6de28094e5d",
      "Authorization": `Bearer ${rpmBearerToken}`
    },
    body: { data: { assetId: asset.id } },
  });
}

// Load Templates from RPM to apply to guest accounts prior to editing/assigning assets
export async function getGender(id:string): Promise<JsonResponseRPM | null> {
  try {
    const response = await apiRequest<JsonResponseRPM>(
      'https://models.readyplayer.me/' + id + ".json",
      {
        method: 'GET'
      }
    );

    if (response.error) {
      console.error('API Error:', response.error || 'Unknown error occurred');
      return null;
    }

    return response.data as JsonResponseRPM;
  } catch (error) {
    console.error('Unexpected error while fetching templates:', error);
    return null;
  }
}