import { useEffect, useRef, useState } from "react"
import { apiRequest, applyEditsToLoggedinUserTemplate, assignTemplateToAvatar, changeGender, equipAssets, equipTopOrBottomIfOutfitApplied, getAssetsByType, getGender, getMyAvatarsFromRPM, getTemplates, save } from "./ReadyPlayerMeComponents/APICalls"
import { useAppDispatch, useAppSelector } from "../../store/hooks"
import { setAssignedTemplate, setAvatarGender, setAvatarId, setCanJumpIn, setCookieDoesntMatchFlag, setEdit, setPlayer, setRpmBearerToken, setRpmId, user } from "../../store/appUser";
import { Avatar } from "@readyplayerme/visage";
import { Template, AvatarDetails, ApiResponse, AssetType, SkinColours, allColours, EditType } from "./ReadyPlayerMeComponents/RPMTypes";
import { UserLogin } from "./ReadyPlayerMeComponents/UserLogin";
import "./ReadyPlayerMeComponents/RPMStyles.css";
import { setSelectedPlayer } from "../../store/party";
import { AssetPicker } from "./ReadyPlayerMeComponents/AssetPicker";
import { cleanMessage } from "../LiveChat/chatWindowComponents/ChatBubble";
import { useNavigate } from "react-router";
import { RPMAccountModal } from "./ReadyPlayerMeComponents/RPMAccountModal";
import Cookies from "js-cookie";
import { ColourPicker } from "../AvatarPage/ColourPicker";

type Props = {
    cssClass: string,
    isModal: boolean,
    close?: Function,
    sendMessage?: Function
}
export const ReadyPlayerMeCustom: React.FC<Props> = ({ cssClass , isModal, close, sendMessage }) => {

    const dispatch = useAppDispatch();
    const history = useNavigate();
    const [userAvatars, setuserAvatars] = useState<Template[]>([])
    const [assets, setAssets] = useState<AvatarDetails[]>([]);
    const [hasToken, sethasToken] = useState(false);
    const [currentAssets, setCurrentAssets] = useState("oufit");
    const [showLoader, setShowLoader] = useState("");
    const [initialCameraDistance, setInitialCameraDistance] = useState(3.2);
    const [selectedTemplate, setSelectedTemplate] = useState<string | null>(null);
    const [openLogin, setOpenLogin] = useState(false);
    const [showAccountModal, setShowAccountModal] = useState(false);
    const [showBtns, setShowBtns] = useState(false);
    const { player, rpmBearerToken, rpmId, avatarGender, avatarId, loggedIntoRPM, needsLoginFlag, cookieDoesntMatchFlag} = useAppSelector(state => state.appUser);
    const { sceneUrl, className } = useAppSelector(state => state.scene)
    const [newName, setNewName] = useState(player.displayName);
    const playerRef = useRef(player)
    const rpmIdRef = useRef(rpmId)
    const avatarIdRef = useRef(avatarId)
    const RPMBearerTokenRef = useRef(rpmBearerToken)

    useEffect(() => {
        console.log(player.glbUrl)
        console.log(Cookies.get("AvatarGlb"))
        if (player.glbUrl === null) {
            const createPlayer = async () => {
                const response = await apiRequest<ApiResponse>(
                    'https://testapp-uy5uxo.readyplayer.me/api/users',
                    {
                        method: 'POST',
                        body: { data: { applicationId: '6620f37fc062d6de28094e5d' } },
                        headers: {
                            'Content-Type': 'application/json',
                            'x-api-key': 'sk_live_ukCGG9Uw2rD1mCJAlV0Yk1JDyozjAIgvTohC',
                        },
                    }
                );

                if (response.error) {
                    console.error('API Error:', response.error);
                } else {
                    console.log('API Data:', response.data?.data);
                    dispatch(setRpmId(response.data?.data.id));
                    if(response.data?.data.id){
                        Cookies.set("rpmId", response.data?.data.id)
                    }
                    dispatch(setRpmBearerToken(response.data?.data.token));
                    if(response.data?.data.token){
                        Cookies.set("rpmToken", response.data?.data.token)
                    }
                    sethasToken(true)
                    getAssets(response.data?.data.id, "outfit")
               
                }
            };

            createPlayer();

        } else {
            setSelectedTemplate(player.glbUrl?.replace(".png", ".glb") + `?v=${Date.now()}`)
            getGender(avatarId).then(res => {
                let gender = res?.outfitGender === "masculine" ? "male": "female"
                console.log("Setting Gender: " + gender)
                dispatch(setAvatarGender(gender))
            })
      
        }
    }, [])

    useEffect(() => {
        playerRef.current = player
        avatarIdRef.current = avatarId
        RPMBearerTokenRef.current = rpmBearerToken
        if (rpmId) {
            rpmIdRef.current = rpmId
        }
    }, [player, rpmId, avatarId, rpmBearerToken])

    useEffect(() => {
        if (hasToken && !loggedIntoRPM) {
            console.log("Getting Templates")
            getTemplates(rpmBearerToken).then(response => {
                if (response)
                    if (Array.isArray(response.data)) {
                        const excludedIndices = [0, 1, 8, 15, 18];

                        const validTemplates = response.data.filter(
                            (template, index) =>
                                template.usageType !== "onboarding" &&
                                !excludedIndices.includes(index)
                        );
                        if(!selectedTemplate){
                            assignTemplate(getRandomAvatar(validTemplates), rpmBearerToken)
                        }
                        
                    }
            });
        }
    }, [hasToken, rpmBearerToken])

    function getMyAvatars(token: string, id: string) {
        getMyAvatarsFromRPM(token, id).then(response => {
            if (response) {
                console.log('API Data:', response.data);
                let templates: Template[] = []
                if (response.data.length) {
                    templates = response.data.map(item => ({
                        imageUrl: `https://models.readyplayer.me/${item.id}.png`,
                        gender: item.data.gender,
                        id: item.id,
                        usageType: "myAvatars"
                    }));
                    setuserAvatars(templates)
                }
            }
        })
    }

    function createNewAvatar(token: string) {
        getTemplates(token).then(response => {
            if (response)
                if (Array.isArray(response.data)) {
                    const excludedIndices = [0, 1, 8, 15, 18];

                    const validTemplates = response.data.filter(
                        (template, index) =>
                            template.usageType !== "onboarding" &&
                            !excludedIndices.includes(index)
                    );
                    assignTemplate(getRandomAvatar(validTemplates), token)
                }
        });
    }

    function assignTemplate(template: Template, token: string) {
        console.log(template)
        dispatch(setAssignedTemplate(template))
        assignTemplateToAvatar(template, token).then(response => {
            if (response)
                if (response.data.id)
                    saveAvatar(response.data.id)
            getAssets(response?.data.id, "outfit")

            dispatch(setAvatarGender(template.gender))
        })
    }

    useEffect(() => {
        if (rpmIdRef.current)
            getAssets(rpmIdRef.current, "outfit")
    }, [rpmIdRef.current, avatarGender])

    function saveAvatar(id: string) {
        save(id).then(response => {
            if (response) {
                let stateToUpdate = {
                    ...playerRef.current,
                    glbUrl: "https://models.readyplayer.me/" + response.data.id + ".glb"
                };
                dispatch(setPlayer(stateToUpdate))
                dispatch(setAvatarId(response.data.id))
                dispatch(setEdit(response))
                if (rpmIdRef.current) {
                    setSelectedTemplate("https://models.readyplayer.me/" + response.data.id + ".glb" + `?v=${Date.now()}`)
                }
                Cookies.set("AvatarGlb","https://models.readyplayer.me/" + response.data.id + ".glb")
                dispatch(setCookieDoesntMatchFlag(false))
            }
        })
    };

    function assignMyAvatar(template: Template) {
        dispatch(setAvatarGender(template.gender))
        let stateToUpdate = {
            ...playerRef.current,
            glbUrl: template.imageUrl.replace("png", "glb")
        };
        dispatch(setPlayer(stateToUpdate))
        dispatch(setAvatarId(template.id))
        if (rpmIdRef.current)
            getAssets(rpmIdRef.current, "outfit")
        setSelectedTemplate(template.imageUrl.replace("png", "glb") + `?v=${Date.now()}`)
    }

    function getAssets(id: string | null | undefined, type: string) {
        if (id !== null || id !== undefined) {
            getAssetsByType(id as string, type, rpmBearerToken, rpmId as string).then(response => {
                if (response) {

                    if (Array.isArray(response.data)) {
                        setAssets(response.data as AvatarDetails[])
                    } else if (response.data) {
                        setAssets(response.data as AvatarDetails[])
                    }
                    setCurrentAssets(type)
                }
            })
        }
    }

    function equipTopOrBottom(asset: AssetType) {
        setShowLoader("")
        equipTopOrBottomIfOutfitApplied(asset, rpmBearerToken, avatarIdRef.current)
        setSelectedPlayer("https://models.readyplayer.me/" + avatarIdRef.current + ".glb" + `?v=${Date.now()}`)
    }

    function updateAvatar(asset: AssetType) {
        setShowLoader("")
        equipAssets(asset, rpmBearerToken, avatarIdRef.current).then(() => {
            saveAvatar(avatarIdRef.current)
        })
    }

    function applyEditsToAccountTemplate(data: EditType, rpmBearerToken: string) {
        setShowLoader("")
        applyEditsToLoggedinUserTemplate(data, rpmBearerToken, avatarIdRef.current).then(() => {
            saveAvatar(avatarIdRef.current)
        })
    }

    function handleAssetSelection(type: string, initialDistance: number) {
        setInitialCameraDistance(initialDistance)
        getAssets(rpmIdRef.current, type)
    }

    function handleGenderChange(gender: "male" | "female") {
        setShowLoader("")
        changeGender({ id: gender, type: 'gender' }, rpmBearerToken, avatarIdRef.current).then(() => {
            saveAvatar(avatarIdRef.current)
            dispatch(setAvatarGender(gender))
            getAssets(rpmIdRef.current, "outfit")
        })
    }

    function getRandomAvatar(templates: Template[]): Template {
        const excludedIndices = [0, 1, 8, 15, 18];

        const validTemplates = templates.filter(
            (template, index) =>
                template.usageType !== "onboarding" &&
                !excludedIndices.includes(index) && template.gender === avatarGender
        );
        const randomIndex = Math.floor(Math.random() * validTemplates.length);
        return validTemplates[randomIndex];
    }

    function handleAvatarName(name: string) {
        if (name.length > 0) {
            setShowBtns(true)
        } else {
            setShowBtns(false)
        }
        let clean = cleanMessage(name)
        setNewName(clean)
    }

    function updateNameOnly() {
        //let nameCookie = Cookies.get("username")
        console.log("updateName Only")
        if (sendMessage != null && playerRef.current.displayName != "") {
            let data = {
                displayName: playerRef.current.displayName,
                avatarUrl: playerRef.current.glbUrl,
                avatarProfilePic: playerRef.current.glbUrl?.replace(".glb", ".png"),
                colour: playerRef.current.colour
            }
            sendMessage("ReactClientManager", "SetLocalAvatar", JSON.stringify(data));
            let stateToUpdate = {
                ...playerRef.current,
                displayName: newName
            };
            dispatch(setPlayer(stateToUpdate))
            setShowBtns(false)
        }
        if (close) {
            close(false)
        }
    }
    function sendAvatarUrlToUnity(player: user) {
        
        if (sendMessage) {
            let data = {
                displayName: player.displayName,
                avatarUrl: player.glbUrl,
                avatarProfilePic: player.glbUrl?.replace(".glb", ".png"),
                colour: player.colour
            }
            sendMessage("ReactClientManager", "SetLocalAvatar", JSON.stringify(data));
            if (close) {
                close(false)
            }

        }
    }

    function handleAvatars(token: string, id: string | null) {
        setShowAccountModal(true)
        if (id)
            getMyAvatars(token, id)
    }

   function handleJumpIn(){
        dispatch(setCanJumpIn(true))
        history("/mv/live/" + className)
   }

    return (
        <div style={{ position: 'absolute', zIndex: 50000, height: isModal ? '95%' : '100%', width: '100%' }}>

            <div className={cssClass}>

                <div style={{ flex: 3, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {
                        selectedTemplate && (
                            <div style={{ height: '100%', display: 'flex', flexDirection: 'row', width: '100%' }}>
                                <div style={{ flex: 3, display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative' }}>
                                    {
                                        selectedTemplate && (
                                            <>
                                                <Avatar
                                                    animationSrc="/animations/Idle.fbx"
                                                    backLightColor="#FFB878"
                                                    backLightIntensity={1}
                                                    background={{
                                                        color: 'rgb(36, 30, 54)',
                                                    }}
                                                    bloom={{
                                                        intensity: 1,
                                                        kernelSize: 1,
                                                        luminanceSmoothing: 1,
                                                        luminanceThreshold: 1,
                                                        materialIntensity: 1,
                                                        mipmapBlur: true,
                                                    }}
                                                    cameraInitialDistance={initialCameraDistance}
                                                    cameraTarget={1.55}
                                                    dpr={2}
                                                    emotion={{
                                                        cheekSquintLeft: 0.3,
                                                        eyeLookInRight: 0,
                                                        eyeLookOutLeft: 0,
                                                        jawOpen: 0.1,
                                                        mouthDimpleLeft: 0.3,
                                                        mouthPressLeft: 0.1,
                                                        mouthSmileLeft: 0.2,
                                                        mouthSmileRight: 0.1,
                                                    }}
                                                    environment="soft"
                                                    fillLightColor="#6794FF"
                                                    fillLightIntensity={3}
                                                    keyLightColor="#FFFFFF"
                                                    keyLightIntensity={0.8}
                                                    modelSrc={selectedTemplate}
                                                    scale={1}
                                                    onLoaded={() => { setShowLoader("none"); }}
                                                    style={{
                                                        borderRadius: '15px 0px 0px 15px'
                                                    }}
                                                />
                                                <div style={{ position: 'absolute', top: 5, left: 5, display: 'flex', gap: 5 }}>
                                                    <button className="join-button" onClick={() => handleGenderChange("male")}>
                                                        <img
                                                            height={30}
                                                            alt="male"
                                                            title="male"
                                                            src="/images/svg/male-svgrepo-com.svg"
                                                        />
                                                    </button>
                                                    <button className="join-button" onClick={() => handleGenderChange("female")}>
                                                        <img
                                                            height={30}
                                                            alt="female"
                                                            title="female"
                                                            src="/images/svg/female-svgrepo-com.svg"
                                                        />
                                                    </button>
                                                </div>

                                                <div style={{
                                                    position: 'absolute',
                                                    bottom: '0',
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    flexWrap: 'wrap',
                                                    gap: '10px',
                                                    justifyContent: 'center',
                                                    width: '100%',
                                                    paddingBottom: '5px'
                                                }}>
                                                    {currentAssets === "outfit"
                                                        ? SkinColours.map((colour, index) => {
                                                            return (
                                                                <div
                                                                    key={index}
                                                                    style={{
                                                                        backgroundColor: colour,
                                                                        borderRadius: '50%',
                                                                        width: '40px',
                                                                        height: '40px',
                                                                        cursor: 'pointer'
                                                                    }}
                                                                    onClick={() => {
                                                                        updateAvatar({
                                                                            type: "skinColor",
                                                                            id: index
                                                                        });
                                                                    }}
                                                                ></div>
                                                            );
                                                        })
                                                        : null}
                                                </div>

                                                <div
                                                    style={{
                                                        overflowX: 'auto',
                                                        position: 'absolute',
                                                        bottom: '0px',
                                                        display: 'flex',
                                                        whiteSpace: 'nowrap',
                                                        justifyContent: 'start',
                                                        width: '100%',
                                                        padding: '10px 0',
                                                    }}
                                                >
                                                    {["hair", "beard", "eyebrows"].includes(currentAssets)
                                                        ? allColours.map((colour, index) => {
                                                            return (
                                                                <div
                                                                    key={index}
                                                                    style={{
                                                                        display: 'inline-block',
                                                                        backgroundColor: colour,
                                                                        borderRadius: '50%',
                                                                        width: '40px',
                                                                        height: '40px',
                                                                        cursor: 'pointer',
                                                                        marginRight: '10px',
                                                                        flexShrink: 0,
                                                                    }}
                                                                    onClick={() => {
                                                                        updateAvatar({
                                                                            type: currentAssets + "Color",
                                                                            id: index,
                                                                        });
                                                                    }}
                                                                ></div>
                                                            );
                                                        })
                                                        : null}
                                                </div>

                                            </>
                                        )
                                    }
                                </div>

                            </div>

                        )
                    }
                    {showLoader === "" && (
                        <div style={{ position: 'absolute', top: '50%', left: '37%' }}>
                            <span className="loader-rpm"></span>
                        </div>
                    )}

                </div>

                <AssetPicker
                    handleAssetSelection={handleAssetSelection}
                    assets={assets}
                    avatarGender={avatarGender}
                    updateAvatar={updateAvatar}
                    equipTopOrBottom={equipTopOrBottom}
                    currentAssets={currentAssets}
                    handleGenderChange={handleGenderChange}
                    close={close ? close : () => console.timeLog("clicked")}
                    isModal={isModal}
                    sendMessage={sendMessage}
                />
            </div>
            {
                !isModal && (
                    <>
                        <div style={{ position: 'absolute', left: 5, margin: '10px', display: 'flex', flexDirection: 'row', gap: 5 }}>
                            <button className="join-button" onClick={() => loggedIntoRPM ? setShowAccountModal(true) : setOpenLogin(true)}>{loggedIntoRPM ? "Avatars" : "Login"}</button>
                            <ColourPicker position='fixed' top='50%' right='60%' buttonClass='join-button' />
                        </div><div style={{ position: 'absolute', right: 5, margin: '10px' }}>
                            <button className="button-85" onClick={() => isModal ? sendAvatarUrlToUnity(player) : handleJumpIn()}>Finish</button>
                        </div>
                    </>
                )
            }

            {showBtns && (
                <div
                    style={{
                        position: 'relative',
                        top: -20,
                        left: 0,
                        width: '95%',
                        height: '95%',
                        backgroundColor: 'rgba(128, 128, 128, 0.5)',
                        pointerEvents: 'auto',
                        zIndex: 500,
                        borderRadius: '15px'
                    }}
                />
            )}
            {needsLoginFlag && !loggedIntoRPM && (
                <div
                    style={{
                        position: 'absolute',
                        top: -20,
                        left: 0,
                        width: isModal ? '95%':'100%',
                        height: isModal ? '95%':'94%',
                        backgroundColor: 'rgba(128, 128, 128, 0.5)',
                        pointerEvents: 'auto',
                        zIndex: 500000,
                        borderRadius: '25px 25px 0 0',
                        display: 'flex',
                        justifyContent: 'center'
                    }}
                ><div style={{display: 'flex', margin: 'auto', fontFamily: 'BackToSchool', backgroundColor: 'white', borderRadius: '20px', padding:'10px'}}>You Must Be Logged in to RPM to Edit this Avatar (created with your account)</div></div>
            )}

            {
               cookieDoesntMatchFlag  && 
                 (
                    <div
                    style={{
                        position: 'absolute',
                        top: isModal ? -20 : 0,
                        left: 0,
                        width: isModal ? '95%' : '100%',
                        height: isModal ? '95%' : '92%',
                        backgroundColor: 'rgba(128, 128, 128, 0.5)',
                        pointerEvents: 'auto',
                        zIndex: 500000,
                        borderRadius: '25px 25px 0 0',
                        justifyContent: 'center',
                        display: 'flex',
                        color: 'black'
                    }}
                    >
                    <div
                        style={{
                        width: '70%',
                        display: 'flex',
                        margin: 'auto',
                        fontFamily: 'BackToSchool',
                        backgroundColor: 'white',
                        borderRadius: '20px',
                        padding: '10px',
                        }}
                    >
                        Your Current Avatar was not created on this browser and therefore you
                        cannot edit it, please login and use an RPM account to use your avatars
                        across browsers/machines
                    </div>
                    </div>
                )}


            {isModal ? (
                <><div style={{ display: 'flex', flexDirection: 'row', position: 'absolute', bottom: '-1%' }}>
                    <div style={{ margin: '10px' }}>
                        <button className="join-button" onClick={() => loggedIntoRPM ? handleAvatars(rpmBearerToken, rpmId) : setOpenLogin(true)}>{loggedIntoRPM ? "Avatars" : "Login"}</button>
                    </div><div style={{ margin: '10px' }}>
                        <button className="join-button"onClick={() => sendAvatarUrlToUnity(player)}>Finish</button>
                    </div>
                </div><div style={{ position: 'absolute', bottom: '1%', right: '8%', zIndex: 5006436554365436 }}>

                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <label
                                htmlFor="avatarName"
                                style={{
                                    color: 'white'
                                }}
                            >
                                Update Name:
                            </label>
                            <input
                                type="text"
                                className="custom-input-profile"
                                value={newName ? newName : ""}
                                onChange={e => handleAvatarName(e.target.value)}
                                onFocus={() => setShowBtns(true)}
                                // onBlur={() => setShowBtns(false)}
                                style={{
                                    marginLeft: '15px',
                                }} />
                            <button
                                className="close-button-rpm"
                                style={{ visibility: showBtns ? 'visible' : 'hidden' }}
                                onClick={() => {
                                    console.log("Clicked");
                                    updateNameOnly();
                                }}
                            >
                                <img src="/images/buttons/check.png" alt="check" width={20} />
                            </button>
                            <button
                                className="close-button-rpm"
                                style={{ visibility: showBtns ? 'visible' : 'hidden' }}
                                onClick={() => {
                                    if (close) {
                                        setNewName(player.displayName);
                                        setShowBtns(false);
                                    }
                                }}
                            >
                                <img src="/images/buttons/cross.png" alt="cross" width={20} style={{ marginLeft: '5px' }} />
                            </button>
                        </div>
                    </div></>
            ) : null}
            <UserLogin
                open={openLogin}
                setOpen={setOpenLogin}
                showAccountModal={setShowAccountModal}
                getMyAvatars={getMyAvatars}
            />
            <RPMAccountModal
                templates={userAvatars}
                loggedintoRPM={loggedIntoRPM}
                assignMyAvatar={assignMyAvatar}
                setOpen={setShowAccountModal}
                open={showAccountModal}
                createNewAvatar={createNewAvatar}
                assignTemplate={assignTemplate}
                applyEditsToAccountTemplate={applyEditsToAccountTemplate}
                getMyAvatars={getMyAvatars}
            />
            
        </div>
    );

}