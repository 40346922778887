import { useEffect, useState } from "react"
import { RouteParams, UnityClient } from "./UnityClient";
import { useParams } from "react-router-dom";
import Cookies from "js-cookie";
import { AvatarPage } from "./AvatarPage/AvatarPage";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { setAvatarId, setCookieDoesntMatchFlag, setNeedsLoginFlag, setPlayer, setRpmBearerToken, setRpmId, user } from "../store/appUser";
import { Scene, setClassName, setCode, setData, setFramework, setIndex, setLoader, setModuleId, setName, setSceneUrl, setStreamingAssets } from "../store/scenes";
import { ScenePage } from "./ScenePages/ScenePage";
import { useSearchParams } from 'react-router-dom';
import { RPMWrapper } from "./ReadyPlayerMe/RPMWrapper";

export const globalIsHost = {
    isHost: false
} 

type Props = {
    scene: Scene,
    scenePage: boolean,
    getProfile: boolean,
    rpmRefresh: boolean
}

export const InboundLinkRouting: React.FC<Props> = ({scene, scenePage, getProfile, rpmRefresh}) => {

    const {id} = useParams<RouteParams>();
    const { player, canJumpIn } = useAppSelector(state => state.appUser)
    const [hasCookie, setHasCookie] = useState(false);
    const [sceneLoaded, setSceneLoaded] = useState(false);
    const hostId = "b2124128-a607-487f-b9de-33b03750cab9";
    const dispatch = useAppDispatch();
    const [searchParams] = useSearchParams();
    searchParams.set('show_call_ui', 'true');
    const queryString = searchParams.toString();

    useEffect(() => {
        dispatch(setLoader(scene.loader))
        dispatch(setData(scene.data))
        dispatch(setFramework(scene.framework))
        dispatch(setCode(scene.code))
        dispatch(setStreamingAssets(scene.streamingAssests))
        dispatch(setName(scene.name))
        dispatch(setModuleId(scene.moduleId))
        dispatch(setClassName(scene.className))
        dispatch(setSceneUrl(scene.url))
        dispatch(setIndex(scene.index))
        setSyle()
        setSceneLoaded(true)
    },[])

    const setSyle = () => {
        document.documentElement.style.setProperty('--background-avatar', `url(${scene.style.background})`);
        document.documentElement.style.setProperty('--primary-color', scene.style.primary_color);
        document.documentElement.style.setProperty('--secondary-color', scene.style.secondary_color);
        document.documentElement.style.setProperty('--accent-color', scene.style.accent_color);
        document.documentElement.style.setProperty('--highlight-color', scene.style.highlight_color);
        document.documentElement.style.setProperty('--error-color', scene.style.error_color);
        document.documentElement.style.setProperty('--background-avata', scene.style.text);
        document.documentElement.style.setProperty('--text-color', scene.style.text);
    }

    useEffect(()=>{
        if(canJumpIn){
            setHasCookie(true)
        }
    },[])

    useEffect(() => {
        if(getProfile){
            fetchData(window.location.origin + "/user-profile")
            .then(data => {
                console.log('Data received:', data);

                dispatch(setPlayer(data))
                setFlags(data)
                if (data.glbUrl === null && Cookies.get("AvatarGlb") === undefined) {
                    setHasCookie(false)
                }
                if (data.glbUrl) {
                    let modelId = data.glbUrl?.split('/').pop()?.split('.')[0] ?? null;
                    window.avatarId = modelId
                }
            })
            .catch(error => {
                console.error('Error:', error);
            });
        } 
    }, []);

    function setFlags(data: user){
        // if their isnt a glb or cookie set flags to false
        if (data.glbUrl === null && Cookies.get("AvatarGlb") === undefined) {
            dispatch(setCookieDoesntMatchFlag(false))
            dispatch(setNeedsLoginFlag(false))
            setHasCookie(false)
        } else {
            // otherwise set tokens from cookies for loading avatar and getting assets
            if(!canJumpIn){
                setHasCookie(false)
            }
            dispatch(setRpmId(Cookies.get("rpmId")))
            dispatch(setRpmBearerToken(Cookies.get("rpmToken")))
            
            // if the avatar was created with rpm account make flag true to show alert
            if(player.createdByRpmAccount){
                dispatch(setNeedsLoginFlag(true))
            } else if (player.glbUrl !== Cookies.get("AvatarGlb")){
                // otherwise if the cookie and avatar dont match and created with rpm is false set cookies dont match flag
                dispatch(setCookieDoesntMatchFlag(player.glbUrl !== null ? true : false))
            }
            
        }

        
        if (data.glbUrl){
            dispatch(setAvatarId(data.glbUrl.replace(".glb", "").split("/").pop()))
        }
    }

  async function fetchData(url: string): Promise<any> {
    try {
        const response = await fetch(url, {
            method: 'GET', 
            headers: {
                'Content-Type': 'application/json',
            },
        });
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error; 
    }
}

    if(id === hostId){
        globalIsHost.isHost = true
    }

    if(scenePage){
        return(
            <>
               {hasCookie ? <UnityClient params={queryString}/> : <ScenePage scene={scene}/>}
            </>
        )
    }
    if(rpmRefresh){
        return(
            <>
                {player.displayName !== null && hasCookie ?   <RPMWrapper/> :  <AvatarPage setCanJumpIn={setHasCookie}/> }
            </>
        )
    }

    return(
        <>
             {sceneLoaded && (
                hasCookie ? <UnityClient params={queryString}/> : <AvatarPage setCanJumpIn={setHasCookie}/>
            )}
        </>
    )
}