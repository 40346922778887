import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { useSpring, animated } from '@react-spring/web';
import { EditType, Template } from './RPMTypes';
import TemplateSelector from './TemplateSelector';

interface FadeProps {
    children: React.ReactElement;
    in?: boolean;
    onClick?: any;
    onEnter?: (node: HTMLElement, isAppearing: boolean) => void;
    onExited?: (node: HTMLElement, isAppearing: boolean) => void;
    ownerState?: any;
}

export const Fade = React.forwardRef<HTMLDivElement, FadeProps>(function Fade(props, ref) {
    const {
        children,
        in: open,
        onClick,
        onEnter,
        onExited,
        ownerState,
        ...other
    } = props;
    const style = useSpring({
        from: { opacity: 0 },
        to: { opacity: open ? 1 : 0 },
        onStart: () => {
            if (open && onEnter) {
                onEnter(null as any, true);
            }
        },
        onRest: () => {
            if (!open && onExited) {
                onExited(null as any, true);
            }
        },
    });

    return (
        <animated.div ref={ref} style={style} {...other}>
            {React.cloneElement(children, { onClick })}
        </animated.div>
    );
});

export type StyleType = {
    position: string;
    top: string;
    left: string;
    transform: string;
    width: string;
    boxShadow: number;
    p: number;
};

export const style: StyleType = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '60%',
    boxShadow: 0,
    p: 0,
};

type Props = {
    open: boolean,
    setOpen: Function,
    templates: Template[],
    loggedintoRPM: boolean,
    assignMyAvatar: (template: any) => void;
    createNewAvatar: Function,
    assignTemplate: Function,
    applyEditsToAccountTemplate: Function,
    getMyAvatars: Function
}
export const RPMAccountModal: React.FC<Props> = ({ open, setOpen, templates, loggedintoRPM, assignMyAvatar, createNewAvatar, assignTemplate, applyEditsToAccountTemplate, getMyAvatars }) => {

    const handleClose = () => setOpen(false);

    return (
        <div >
            <Modal
                aria-labelledby="spring-modal-title"
                aria-describedby="spring-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        TransitionComponent: Fade,
                    },
                }}
                sx={{
                    zIndex: 10000000,
                }}
            >
                <Fade in={open}>
                    <Box component="div" sx={style}>
                        <div style={{ zIndex: 60000, position: 'relative', margin: 'auto', height: '70%' }}>

                            {templates.length > 0 && (<TemplateSelector
                                templates={templates}
                                loggedIntoRPM={loggedintoRPM}
                                assignMyAvatar={assignMyAvatar}
                                closeModal={setOpen}
                                createNewAvatar={createNewAvatar}
                                assignTemplate={assignTemplate}
                                applyEditsToAccountTemplate={applyEditsToAccountTemplate}
                                getMyAvatars={getMyAvatars}
                            />)}
                        </div>
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
}
