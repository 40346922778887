import { ReadyPlayerMeCustom } from "./ReadPlayerMeCustom"

export const RPMWrapper:React.FC = () => {
    return(
        <div style={{ zIndex: 5 }}  className="hero-image">
          <div className="overlay-hero" style={{ zIndex: 0 }}></div>
          <div  className='profile-modal' style={{ 
            position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '70%',
                }}>
                <ReadyPlayerMeCustom cssClass='rpm-container' isModal={false} />
          </div>
    </div>
    )
}