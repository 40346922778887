import { useState, useEffect } from "react";
import { AvatarDisplay } from "./AvatarDisplay";
import { useSelector, useDispatch } from "react-redux";
import Cookies from "js-cookie";
import { useNavigate } from 'react-router-dom';
import { Trail } from "./TrailText";

import styles from '../../styles.module.css'
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { setAvatarGender, setPlayer } from "../../store/appUser";
import { cleanMessage } from "../LiveChat/chatWindowComponents/ChatBubble";
import { ColourPicker } from "./ColourPicker";
import { Diversity1 } from "@mui/icons-material";

interface WindowSize {
    width: number,
    height: number
}

type TextState = {
    text1: boolean,
    text2: boolean,
    shown: boolean
}

type Props = {
    setCanJumpIn: Function 
}
export const AvatarPage: React.FC<Props> = ({setCanJumpIn}) => {

    const history = useNavigate();
    let dispatch = useDispatch();
    const [windowSize, setWindowSize] = useState<WindowSize>({
        width: window.innerWidth,
        height: window.innerHeight
      });
    const {player} = useAppSelector((state) => state.appUser)
    const {className} = useAppSelector((state) => state.scene)
    const [open, set] = useState<TextState>({text1: false, text2: false, shown: false})
    const [prompt, setprompt] = useState<string>("")

    // useEffect(() => {
    //     let cookie = Cookies.get("avatarUrl")
    //     if(cookie){
    //         dispatch(setAvatarGlb(cookie))
    //         handleShowAvatar(cookie)
    //     }
    // })
    
    useEffect(() => {
        const handleResize = () => {
          setWindowSize({
            width: window.innerWidth,
            height: window.innerHeight
          });
        };
    
        window.addEventListener('resize', handleResize);
    
        return () => window.removeEventListener('resize', handleResize);
    }, []);


    function handleAvatarName(name:string){
        let clean = cleanMessage(name)
        const urlRegex = /(https?:\/\/[^\s]+|www\.[^\s]+)/g;
        if(urlRegex.test(clean)){
            clean = clean.replace(urlRegex, "*")
        }
        let stateToUpdate = {
            ...player,
            displayName: clean, 
        };
        dispatch(setPlayer(stateToUpdate))
        Cookies.set("username", clean)
    }


    return (
    <div className="hero-image">
        <div className="header avatar-heading">
            <h1 className="heading avatar-heading" style={{marginBottom: '100px'}}>Avatar Selection</h1>
        </div>
        <div className="avatar-page">
            {/* Left Column with Inputs */}
            <div className="avatar-input-column">
                <div className={`avatar-input-area1 ${prompt}`}>
                    <h3 className="headings avatar-heading" style={{textAlign: 'center'}}>Enter your Name:</h3>
                    <div>
                        <input
                            className="search-input"
                            placeholder="Enter A Name"
                            style={{
                                padding: "20px 50px",
                                margin: "20px"
                            }}
                            value={player.displayName ? player.displayName : ""}
                            onChange={(e) => {
                                handleAvatarName(e.target.value);
                            }}
                        />
                    </div>
                </div>

                <div className={`avatar-input-area2`}>
                    <div style={{ display: 'flex', flexDirection: 'column', alignContent: 'center', textAlign: 'center'}}>
                    <h4 className="headings avatar-heading">Create Your Avatar</h4>
                        <div style={{ marginTop: '5%', width: '100%' }}>
                        {
                                        player.glbUrl ?  <button
                                        className='join-button'
                                        onClick={() =>{
                                             history("/mv/rpm/" + className)
                                             setCanJumpIn(true)                                            
                                            }}
                                        disabled={!(!!player.displayName)}
                                    >
                                        {!!player.displayName ? "Begin" : "Choose Name"}
                                    </button> :  <div style={{display: 'flex', flexDirection: 'row', gap: 5, justifyContent: 'center'}}><button
                                            className='join-button'
                                            onClick={() => {
                                                dispatch(setAvatarGender('male'))
                                                setCanJumpIn(true)
                                                history("/mv/rpm/" + className)
                                            }}
                                            disabled={!(!!player.displayName)}
                                        >
                                            Male
                                        </button>
                                        <button
                                            className='join-button'
                                            onClick={() => {
                                                dispatch(setAvatarGender('female'))
                                                setCanJumpIn(true)
                                                history("/mv/rpm/" + className)
                                            }}
                                            disabled={!(!!player.displayName)}
                                        >
                                            Female
                                        </button></div>
                                    }
                        </div>
                    </div>
                    
                </div>           
            </div>
            
        
        </div>
        <div className="overlay-hero"></div>
    </div>

    )
}